.App {
  height: 100%;
}

.card-style {
  padding: 20px;
  border-radius: 6px;
  background-color: white;
  overflow: hidden;

  box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
}

.Toastify__toast--success {
  background: #28a745 !important;
}

.Toastify__toast--error {
  background: #EF3E36 !important;
}

.Toastify__toast--info {
  background: #13293D !important;
}

.clickable-row:hover, .delete-button {
  cursor: pointer;
}

.no-hover:hover {
  cursor: default;
}

.page-container {
  margin: 20px;
}

button:disabled {
  background: #777777 !important;
  border: none !important;
}

button:disabled:hover{
  cursor: default !important;
}