.logout-button {
    cursor: pointer
}

.navbar-logo {
    position: absolute;
    left: 5px;
    width: 50px;
}

.navbar-title {
    margin-left: 40px;
    font-size: 15px;
}

.sidebar-toggle {
    cursor: pointer;
    padding: 4px;
}

.sidebar-toggle:hover {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.1);
}

.shadow {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;

}
