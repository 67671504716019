.sidebar-custom {
    height: 100%;
    width: 180px;
    min-width: 180px;
    background-color: #05204A;
    float: left;
}

.sidebar-ul {
    width: 100%;
}

.sidebar-item-custom {
    color: white;
    text-decoration: none !important;
}

.active-sidebar {
    border-right: 5px solid #EF3E36;
    background-color: #13293D;
}

.collapsed {
    width: 60px;
    min-width: 60px;
}

.sidebar-li {
    width: 100%;
}

.sidebar-item {
    display: flex;
    padding: 20px;
    padding-left: 0;
}

.sidebar-item:hover {
    color: white;
    background-color: #001427;
}

.sidebar-item-icon {
    margin-left: 20px;
    margin-right: 20px;
}