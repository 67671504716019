.dashboard {
    height: 100%;
}

.content-container {
    height: 100%;
    padding-top: 55px;
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.main-content {
    width: 100%;
    max-height: 100%;
    overflow: scroll;
    background-color: rgb(248,248,255);
}