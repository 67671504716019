.Login {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(248,248,255);
}

.logo {
    width: 150px;
}

.login-title {
    margin-bottom: 50px;
    font-family: serif;
}

.form-signin {
    width: 100%;
    max-width: 430px;
    padding: 40px;
    margin: 0 auto;
    background-color: white;
    border-radius: 6px;
    
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 10px 8px rgba(0, 0, 0, 0.12);
}

.form-control {
    margin-bottom: 10px;
}

.login-button {
    background-color: rgb(50,50,150);
    border: none;
}

@media screen and (max-width: 600px) {
    .form-signin {
        padding: 20px;
        max-width: 350px;
    }

    .logo {
        width: 120px;
    }
    
    .login-title {
        font-size: 20px;
    }
}